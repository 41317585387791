import Truck from '../Truck/Truck'
import BarLoader from 'react-spinners/BarLoader'

export default function TruckPage({
  trucks = [],
  setActivePage,
  setSelectedTruck
}) {
  return (
    <div className="flex flex-col gap-4 py-3 px-4 overflow-y-scroll flex-1">
      {trucks != null ? (
        trucks.map((item) => (
          <Truck
            key={Math.random()}
            truckDataProps={item}
            setActivePage={setActivePage}
            setSelectedTruck={setSelectedTruck}
          />
        ))
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'fixed',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999999,
            top: 0,
            left: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.3)'
          }}
        >
          <BarLoader color="#3290EC" size={20} />
        </div>
      )}
    </div>
  )
}
